if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark')
} else {
    document.documentElement.classList.remove('dark')
}

window.addEventListener('scroll', function () {
    const header = document.getElementById('header');
    const scrollPosition = window.scrollY;

    if (scrollPosition > 300) {
        if (!header.classList.contains('mini')) {
            header.classList.add('mini');
            header.classList.add('bg-black');
        }
    } else {
        if (header.classList.contains('mini')) {
            header.classList.remove('mini');
            header.classList.remove('bg-black');
        }
    }
})